<template>
  <v-container>
    <v-col cols="12" sm="12" offset-sm="0">
      <v-card class="maincard">
        <v-container>
          <v-row align="center">
            <v-col cols="12" xs="12" sm="6">
              <v-card-title>Rechnungsverwaltung</v-card-title>
            </v-col>
            <v-col cols="12" xs="12" sm="6" style="text-align: right;">
              <v-btn to="/bill/edit" color="success">
                <v-icon style="margin-right: 10px;">mdi-file-plus</v-icon>
                <span>Neue Rechnung</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>

        <ItemList />
      </v-card>
    </v-col>
  </v-container>
</template>

<script>
// @ is an alias to /src
import ItemList from "@/components/bills/BillList";

export default {
  name: "BillMgmt",
  data: () => ({
    countrys: "",
    mapFields: {
      status: "Status"
    }
  }),
  components: { ItemList },
  methods: {},
  computed: {},
  created() {},
  beforeCreate() {
  }
};
</script>
<style scoped></style>
