<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="12" offset-sm="0">
        <v-card id="listcard" :loading="loading">
          <h2 v-if="!!!itemList">Keine Rechnungen gefunden</h2>
          <v-data-table
            :headers="headers"
            :items="itemList"
            :search="search"
            :sort-by="['billnr']"
            :sort-desc="[true]"
            :itemsPerPage="-1"
            multi-sort
          >
            <template slot="item.date" slot-scope="{ item }">
              <span>{{ formattedDate(item.date) }}</span>
            </template>

            <template slot="item.paydate" slot-scope="{ item }">
              <span>{{
                item.status == "60" ? formattedDate(item.paydate) : ""
              }}</span>
            </template>

            <template slot="item.validTS" slot-scope="{ item }">
              <span
                :class="isBillOutdated && status != '60' ? 'dateWarning' : ''"
                >{{ formattedDate(item.validTS) }}</span
              >
            </template>

            <template slot="item.totalNetto" slot-scope="{ item }">
              <span> {{ currencyFormat(item.totalNetto) }} </span>
            </template>

            <template slot="item.totalBrutto" slot-scope="{ item }">
              <span> {{ currencyFormat(item.totalBrutto) }} </span>
            </template>
            <template slot="item.createdBy" slot-scope="{ item }">
              <span>{{ userKuerzel(item.createdBy) }}</span>
            </template>

            <template v-slot:top>
              <v-text-field
                v-model="search"
                label="Suche"
                class="pa-4"
                :outlined="true"
                :filled="true"
                :clearable="true"
                background-color="#e8eff7"
              ></v-text-field>
            </template>
            <template slot="item.status" slot-scope="{ item }">
              <v-chip small :color="statusColor(item.status)">
                {{ statusTitle(item.status) }}
              </v-chip>
            </template>
            <template slot="item.mandant" slot-scope="{ item }">
              {{ mandantName(item.mandant) }}
            </template>
            <template slot="item.actions" slot-scope="{ item }">
              <v-icon small class="mr-2" @click="itemDetails(item)"
                >mdi-eye</v-icon
              >

              <v-menu dense offset-y align="right">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon dense v-bind="attrs" v-on="on"
                    >mdi-dots-vertical</v-icon
                  >
                </template>
                <v-list dense>
                  <v-list-item
                    @click="editItem(item)"
                    v-if="!!!isPayed(item) || isSuperUser"
                  >
                    <v-list-item-icon>
                      <v-icon>mdi-pencil</v-icon>
                    </v-list-item-icon>
                    Bearbeiten
                  </v-list-item>
                  <v-list-item
                    @click="askDeleteItem(item)"
                    v-if="!!isEntwurf(item)"
                  >
                    <v-list-item-icon>
                      <v-icon>mdi-delete</v-icon>
                    </v-list-item-icon>
                    Löschen
                  </v-list-item>
                  <v-list-item @click="downloadPdf(item)">
                    <v-list-item-icon>
                      <v-icon>mdi-download</v-icon> </v-list-item-icon
                    >PDF anzeigen</v-list-item
                  >

                  <v-list-item
                    @click="billCompleted(item)"
                    v-if="!!isEntwurf(item)"
                  >
                    <v-list-item-icon>
                      <v-icon>mdi-cash</v-icon>
                    </v-list-item-icon>
                    Rechnung fertigstellen</v-list-item
                  >

                  <v-list-item
                    @click="billSend(item)"
                    v-if="!!!isEntwurf(item)"
                  >
                    <v-list-item-icon>
                      <v-icon>mdi-email</v-icon>
                    </v-list-item-icon>
                    Rechnung senden</v-list-item
                  >

                  <v-list-item v-if="!!!isPayed(item)" @click="billPayed(item)">
                    <v-list-item-icon>
                      <v-icon>mdi-cash</v-icon>
                    </v-list-item-icon>
                    Rechnung bezahlt</v-list-item
                  >
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dlgDelete" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Rechnung löschen?</v-card-title>
        <v-card-text>Soll die Rechnung wirklich gelöscht werden?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" outlined text @click="dlgDelete = false"
            >Abbrechen</v-btn
          >
          <v-btn dark color="red darken-1" @click="deleteItem">
            <v-icon>mdi-delete</v-icon>Löschen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dlgDetails"
      max-width="90%"
      @keydown.esc="dlgDetails = false"
    >
      <v-toolbar dark>
        <v-toolbar-title>Rechnungsdetails</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="dlgDetails = false"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-toolbar>

      <v-card tile v-if="detailItem">
        <v-card-title class="headline">
          <v-container>
            <v-row>
              <v-col cols="12" sm="4">
                Rechnung {{ detailItem.billnr }}<br />
                <span class="mandant"
                  >Mandant:
                  <v-chip>{{ mandantName(detailItem.mandant) }}</v-chip></span
                >
              </v-col>
              <v-col cols="12" sm="4">
                Status:
                <v-chip :color="statusColor(detailItem.status)">
                  {{ statusTitle(detailItem.status) }}</v-chip
                >
              </v-col>
              <v-col cols="12" sm="4">
                Re. Datum: {{ formattedDate(detailItem.date) }}
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>
        <v-card-text>
          <v-container>
            <h2 class="">Kunde:</h2>
            <v-row>
              <v-col cols="12" sm="4">
                <h3 class="ma-0 pa-0 mb-2">Kundendaten</h3>
                <span class="detailtitle">Kdnr.:</span>
                {{ detailItem.customer.kdnr }}<br />
                <span class="detailtitle">Titel:</span>
                {{ detailItem.customer.titel }}<br />
                <span class="detailtitle">Anrede:</span>
                {{ detailItem.customer.anrede }}<br />
                <span class="detailtitle">Name1:</span>
                {{ detailItem.customer.name1 }} <br />
                <span class="detailtitle">Name2:</span>
                {{ detailItem.customer.name2 }} <br />
                <span class="detailtitle">Adresse:</span>
                {{ detailItem.customer.adresse }}<br />
                <span class="detailtitle">PLZ/Ort:</span>
                {{ detailItem.customer.plz }} -
                {{ detailItem.customer.ort }}
                <br />
                <span class="detailtitle">Land:</span>
                {{ detailItem.customer.land }}<br />
                <span class="detailtitle" v-if="detailItem.customer.uid"
                  >UID: {{ detailItem.customer.uid }}</span
                >
              </v-col>
              <v-col cols="12" sm="4">
                <h3 class="ma-0 pa-0 mb-2">Kontaktdaten</h3>
                <span class="detailtitle">Email:</span>
                {{ detailItem.customer.email }}<br />
                <span class="detailtitle">Telefon:</span>
                {{ detailItem.customer.telefon }} <br />
                <span class="detailtitle">Fax:</span>
                {{ detailItem.customer.fax }} <br />
                <span class="detailtitle">Web:</span>
                {{ detailItem.customer.web }}<br />
              </v-col>
              <v-col cols="12" sm="4">
                <h3 class="ma-0 pa-0 mb-2"></h3>
                <span class="detailtitle">Ersteller:</span>
                {{ detailItem.createdBy }}<br />
                <span class="detailtitle">Erstellungsdatum:</span>
                {{ formattedDateTime(detailItem.createdTS) }}
                <br />
                <span class="detailtitle">Bearbeitet:</span>
                {{ detailItem.createdBy }}<br />
                <span class="detailtitle">Bearbeitungsdatum:</span>
                {{ formattedDateTime(detailItem.modifiedTS) }} <br />
              </v-col>
            </v-row>
            <h2>Produkte:</h2>
            <v-row>
              <v-col>
                <v-simple-table>
                  <thead>
                    <tr>
                      <th class="text-left">Art.Nr.</th>
                      <th class="text-left">Bezeichnung</th>
                      <th class="text-right">Menge</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(product, index) in detailItem.products"
                      :key="index"
                    >
                      <td class="text-left">{{ product.productnr }}</td>
                      <td class="text-left">
                        {{ product.name }}<br />{{ product.description }}
                      </td>
                      <td class="text-right">
                        {{ product.quantity }} {{ product.unit }}
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            outlined
            text
            @click="dlgDetails = false"
            >Schließen</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <MailPreviewDialog ref="mailDialog" />
  </v-container>
</template>

<script>
import { eventBus } from "../../main";
import moment from "moment";
import itemStatus from "@/helpers/itemStatus.js";
import PDFTemplateService from "@/services/PDFTemplateService.js";
import MailPreviewDialog from "@/components/MailPreviewDialog";
import ContactService from "@/services/ContactService";

export default {
  name: "BillList",
  data: () => ({
    loading: false,
    search: "",
    dlgDelete: false,
    dlgDetails: false,
    detailItem: null,
    selectedItem: false,
    headers: [
      { text: "Status", value: "status" },
      { text: "Nr", value: "billnr" },
      { text: "Kdnr", value: "customer.kdnr" },
      { text: "Kunde", value: "customer.name1" },
      { text: "Datum", value: "date" },
      { text: "Bezahlt am", value: "paydate" },
      { text: "Zahlbar bis", value: "validTS" },
      // { text: "Produkte", value: "products.length" },
      { text: "Mandant", value: "mandant" },
      { text: "Ersteller", value: "createdBy" },
      { text: "Netto", value: "totalNetto", align: "right" },
      { text: "Brutto", value: "totalBrutto", align: "right" },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        width: "110px",
        align: "right",
      },
    ],
  }),
  components: { MailPreviewDialog },
  methods: {
    isEntwurf(item) {
      return item.status == itemStatus.Draft.code;
    },
    isPayed(item) {
      return item.status == itemStatus.Payed.code;
    },
    isDelivered(item) {
      return item.status == itemStatus.Delivered.code;
    },
    isInvoiced(item) {
      return item.status == itemStatus.Invoiced.code;
    },
    isBillOutdated(item) {
      if (moment().isafter(item.validTS)) {
        return true;
      }
      return false;
    },
    billSend(item) {
      item.type = "bill";
      this.$refs.mailDialog.setDataItem(item);
      this.$refs.mailDialog.showDialog();
    },
    mandantName(mid) {
      let mandant = this.$store.getters["mandant/getMandantenList"].find(
        (item) => item._id == mid
      );
      if (mandant) {
        return mandant.firmenname;
      } else {
        return "";
      }
    },
    statusTitle(s) {
      let status = "Neu";

      for (const key of Object.keys(itemStatus)) {
        if (itemStatus[key].code == s) {
          status = itemStatus[key].title;
        }
      }

      return status;
    },
    statusColor(status) {
      let color = "";
      if (status == itemStatus.Payed.code) {
        color = "success";
      } else if (status == itemStatus.Invoiced.code) {
        color = "primary";
      }
      return color;
    },
    askDeleteItem(item) {
      this.dlgDelete = true;
      this.selectedItem = item;
    },
    editItem(item) {
      this.$router.push({ name: "bill-edit", params: { id: item._id } });
    },
    itemDetails(item) {
      this.dlgDetails = true;
      this.detailItem = item;
      ContactService.get(item.customer._id)
        .then((response) => {
          if (response.data) {
            this.detailItem.customer = response.data.data;
          }
        })
        .catch((error) => {
          this.$notify({
            group: "dataline",
            type: "error",
            title: "Fehler bei Kundenabfrage",
            text:
              "Die Kundendaten konnten nicht abgefragt werden: " +
              error.message,
          });
        });
    },
    downloadPdf(item) {
      this.$store.commit("SET_APP_LOADING", true);
      PDFTemplateService.getPdfByObjectId(item._id, "bill")
        .then((response) => {
          let FileSaver = require("file-saver");
          let filename = response.data.data.filename;
          const bytes = new Uint8Array(response.data.data.pdfData.data);
          FileSaver.saveAs(
            new Blob([bytes], {
              type: "application/pdf;",
            }),
            filename
          );
        })
        .catch((error) => {
          if (error.response.status != 401) {
            this.$notify({
              group: "dataline",
              type: "error",
              title: "Fehler beim Download",
              text: "PDF Datei konnte nicht geladen werden.",
            });
          }
        })
        .finally(() => {
          this.$store.commit("SET_APP_LOADING", false);
        });
    },
    billCompleted(item) {
      item.date = moment().format("YYYY-MM-DD");
      item.status = itemStatus.Invoiced.code;
      this.billSave(item);
    },
    billPayed(item) {
      item.paydate = moment().format("YYYY-MM-DD");
      item.status = itemStatus.Payed.code;
      this.billSave(item);
    },
    billSave(item) {
      this.$store.commit("SET_APP_LOADING", true);
      return this.$store
        .dispatch("bill/saveBill", item)
        .then((result) => {
          //if success redirecht to list

          this.$notify({
            group: "dataline",
            type: "success",
            title: "Rechnung gespeichert",
            text: "Rechnung " + result.billnr + " erfolgreich gespeichert",
          });
        })
        .catch((error) => {
          //on error alert error
          console.log(error);
          if (error.response.status != 401) {
            this.$notify({
              group: "dataline",
              type: "error",
              title: "Fehler",
              text: error.message,
            });
          }
        })
        .finally(() => {
          this.$store.commit("SET_APP_LOADING", false);
        });
    },
    formattedDate(dt) {
      return moment(dt).format("DD.MM.YYYY");
    },
    formattedDateTime(dt) {
      return moment(dt).format("DD.MM.YYYY HH:mm:ss");
    },
    userKuerzel(email) {
      let user = this.$store.getters["user/getUserList"].find(
        (item) => item.email == email
      );
      if (user) {
        return user.kuerzel;
      } else {
        return email;
      }
    },
    deleteItem() {
      this.dlgDelete = false;
      this.$store
        .dispatch("bill/deleteItem", this.selectedItem)
        .then((result) => {
          if (result) {
            this.$notify({
              group: "dataline",
              type: "info",
              title: "Rechnung gelöscht",
              text: "Rechnung " + this.selectedItem.billnr + " wurde gelöscht",
            });
            this.selectedItem = null;
          }
        })
        .catch((error) => {
          if (error.response.status != 401) {
            this.$notify({
              group: "dataline",
              type: "error",
              title: "Fehler",
              text: "Fehler beim löschen der Rechnung. " + error.message,
            });
          }
        });
    },
    currencyFormat(value = 0) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(value);
    },

    toggleMandantHeader() {
      const colMandant = { text: "Mandant", value: "mandant" };
      const index = this.headers.findIndex(function (item) {
        return item.value === colMandant.value;
      });
      //Only show Mandant column on all selected
      if (this.$store.getters["mandant/getWorkingMandant"] == "all") {
        if (index == -1) {
          this.headers.splice(5, 0, colMandant);
        }
      } else {
        if (index > -1) {
          this.headers.splice(index, 1);
        }
      }
    },

    loadItems(mandant) {
      this.loading = true;
      this.toggleMandantHeader();
      this.$store
        .dispatch("bill/fetchItemList", mandant)
        .then(() => {
          return this.$store.dispatch("user/fetchUserList");
        })
        .catch((error) => {
          if (error.response.status != 401) {
            this.$notify({
              group: "dataline",
              type: "error",
              title: "Fehler",
              text: error.message,
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

  computed: {
    itemList() {
      return this.$store.getters["bill/getItemList"];
    },
    isSuperUser() {
      return this.$store.getters["user/isSuperUser"];
    },
  },

  created() {
    eventBus.$on("mandantChanged", (data) => {
      this.loadItems(data);
    });

    this.loadItems(this.$store.getters["mandant/getWorkingMandant"]);
  },
};
</script>

<style scoped>
.dateWarning {
  color: red;
}
.detailtitle {
  font-weight: bold;
}
.mandant {
  font-size: 16px;
  font-weight: bold;
}
#listcard {
  margin: 20px;
}
</style>
